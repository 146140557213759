import { FC, useCallback, useState } from 'react'

import { captureException } from '@sentry/browser'
import { isAzureTokenError } from 'api/src/common/utils'
import {
  AnalyseNewEmailsMutation,
  AnalyseNewEmailsMutationVariables,
  CreateAzureTokenMutation,
  CreateAzureTokenMutationVariables,
  DeleteAzureTokenMutation,
  DeleteAzureTokenMutationVariables,
  FindLandlordsByMembership,
  FindLandlordsByMembershipVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import { SelectionType } from 'src/components/Library/SelectWithSort/SelectWithSort'
import { openWindowWithBlockCheck } from 'src/lib/helpers'
import { useAuth } from 'src/Providers'

import HelperArrow from '../HelperArrow/HelperArrow'
import { LANDLORDS_BY_MEMBERSHIP } from '../Settings/SentimentAnalysis/SettingsSentimentAnalysisQueries'

import SentimentAnalysisCell from './SentimentAnalysisCell'
import {
  FilterByOption,
  SADateFilterOptions,
  SASortOptions,
  SentimentAnalysisRatingOptions,
  SentimentAnalysisUserBarProps,
  UserPreview,
  SentimentAnalysisFlagOptions,
  SentimentStatusSelectOptions,
} from './SentimentAnalysisHelper'
import SentimentAnalysisFilterBar from './SentimentAnalysisList/SentimentAnalysisFilterBar'
import {
  AzureToken,
  OverallESIScore,
  SentimentAnalysisRefetch,
  SentimentAnalysisPermission,
} from './SentimentAnalysisPermissionCell'
import {
  ANALYSE_NEW_EMAILS,
  DELETE_AZURE_TOKEN,
  GENERATE_AZURE_AUTH_CODE,
} from './SentimentAnalysisQueries'
import SentimentAnalysisUserBar from './SentimentAnalysisUserBar/SentimentAnalysisUserBar'
import { validateTokenExpiry } from './SentimentAnalysisUtils'

interface SentimentAnalysisProps {
  azureToken: AzureToken
  overallESIScore: OverallESIScore
  refetchPermissions: SentimentAnalysisRefetch
  sentimentAnalysisPermission: SentimentAnalysisPermission
}

const SentimentAnalysis: FC<SentimentAnalysisProps> = ({
  azureToken,
  overallESIScore,
  refetchPermissions,
  sentimentAnalysisPermission,
}) => {
  // Current User Info
  const { currentUser } = useAuth()

  const currentUserData: UserPreview = {
    ...overallESIScore,
    name: currentUser.userData.name,
    position: currentUser.userData.position,
    avatarUrl: currentUser.userData.avatarUrl,
  }

  const [landlordFilterOptions, setLandlordFilterOptions] = useState<
    FilterByOption[]
  >([])

  useQuery<FindLandlordsByMembership, FindLandlordsByMembershipVariables>(
    LANDLORDS_BY_MEMBERSHIP,
    {
      onCompleted: (data) => {
        if (data?.landlords?.length > 0) {
          // Get membership options for filter
          const landlords = data?.landlords
          const sortedLandlords = [...landlords].sort((a, b) => {
            return a?.name > b?.name ? 1 : -1
          })

          setLandlordFilterOptions(sortedLandlords)
        }
      },
    },
  )

  const [sortByValues, setSortByValues] = useState<SelectionType>({
    value: SASortOptions.esi,
    asc: true,
  })

  const [filterDateRange, setFilterDateRange] = useState<SADateFilterOptions>(
    SADateFilterOptions.ALL,
  )
  const [filterByLandlord, setFilterByLandlord] = useState<FilterByOption[]>([])

  const [filterESIRating, setFilterESIRating] =
    useState<SentimentAnalysisRatingOptions>(SentimentAnalysisRatingOptions.ALL)

  const [selectedFlagFilter, setSelectedFlagFilter] =
    useState<SentimentAnalysisFlagOptions>(SentimentAnalysisFlagOptions.ALL)

  const [filterByClientStatus, setFilterByClientStatus] =
    useState<SentimentStatusSelectOptions>(SentimentStatusSelectOptions.ALL)

  const [totalFilteredLandlordsCount, setTotalFilteredLandlordsCount] =
    useState<number>(0)

  // Some States
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false)
  const [isReAuthenticating, setIsReAuthenticating] = useState<boolean>(false)
  const [isAnalysing, setIsAnalysing] = useState<boolean>(false)
  const [isUnlinking, setIsUnlinking] = useState<boolean>(false)
  const [azureConnected, setAzureConnected] = useState<boolean>(
    !!azureToken?.refreshTokenExpiresOn,
  )

  const noSavedToken = azureToken === null

  // Refresh Auth Token Check
  let savedTokenIsActive = false
  let refreshTokenExpiresOn = null

  // for intercom tours we need to know if the user is going to hit the Auth page
  // as the tour will need to run AFTER the user has authenticated

  window.Intercom('update', {
    sentiment_analysis_authenticated: azureConnected,
  })

  if (azureToken) {
    refreshTokenExpiresOn = azureToken.refreshTokenExpiresOn
    savedTokenIsActive = validateTokenExpiry(refreshTokenExpiresOn)
  }

  const userIsAuthenticated = savedTokenIsActive || azureConnected

  // Analyse the new emails
  const [analyzeNewEmails] = useMutation<
    AnalyseNewEmailsMutation,
    AnalyseNewEmailsMutationVariables
  >(ANALYSE_NEW_EMAILS, {
    onCompleted: () => {
      toast.success(
        'Emails will be analysed.\nResults will be available shortly.',
        {
          duration: 5000,
        },
      )
      refetchPermissions()
      setIsAnalysing(false)
    },
    onError: (error) => {
      if (isAzureTokenError(error)) {
        generateAzureCode(true)
        return
      }

      setIsAnalysing(false)
      const quotaReached = error.message.includes('Quota reached')
      toast.error(quotaReached ? error.message : 'An error occurred', {
        duration: 5000,
      })
    },
    refetchQueries: ['FindSentimentAnalysisQuery'],
    awaitRefetchQueries: true,
  })

  // Call this function to analyse emails
  const analyseLandlordEmails: SentimentAnalysisUserBarProps['analyseLandlordEmails'] =
    useCallback(
      (variables) => {
        setIsAnalysing(true)
        // Fire and forget (no await), so we get a re-render and spinner starts immediately
        // analyzeNewEmails will handle success and error events
        analyzeNewEmails({
          variables,
        })
      },
      [analyzeNewEmails],
    )

  // Create new azure token
  const [createAzureToken] = useMutation<
    CreateAzureTokenMutation,
    CreateAzureTokenMutationVariables
  >(GENERATE_AZURE_AUTH_CODE, {
    onCompleted: (result) => {
      setIsAuthenticating(false)
      openWindowWithBlockCheck(result.azureGenerateAppCodeRequest.url)
    },
    onError: (error) => {
      captureException(error, {
        extra: { message: 'Error generating Azure token' },
      })
      toast.error('An error occurred', {
        duration: 5000,
      })
      setIsAuthenticating(false)
    },
  })

  const [deleteAzureToken] = useMutation<
    DeleteAzureTokenMutation,
    DeleteAzureTokenMutationVariables
  >(DELETE_AZURE_TOKEN, {
    onCompleted: () => {
      setIsUnlinking(false)
      setAzureConnected(false)
      refetchPermissions()
    },
    onError: (error) => {
      setIsUnlinking(false)
      captureException(error, {
        extra: { message: 'Error deleting Azure token' },
      })
      toast.error('An error occurred', {
        duration: 5000,
      })
    },
  })

  const unlinkEmailAccount = () => {
    setIsUnlinking(true)
    deleteAzureToken({ variables: { appName: 'EMAIL_SENTIMENT' } })
  }

  // Call this function to generate new token
  const generateAzureCode = (reAuth = false) => {
    reAuth && setIsReAuthenticating(true)

    setIsAuthenticating(true)
    // Fire and forget (no await), so we get a re-render and spinner starts immediately
    // createAzureToken will handle success and error events
    createAzureToken({
      variables: {
        input: {
          appName: 'EMAIL_SENTIMENT',
        },
      },
    })
  }

  // LISTEN for event broadcast on new tab
  const bc = new BroadcastChannel('sentiment_analysis_azure_authorisation')

  bc.onmessage = async (event) => {
    // Refetch permissions, regardless of success or failure - may have worked
    await refetchPermissions()

    if (event.data === 'Success') {
      setAzureConnected(true)

      if (isReAuthenticating) {
        analyseLandlordEmails()
      }

      setIsReAuthenticating(false)
      setIsAuthenticating(false)
    }
  }

  const userHasPreviousData =
    sentimentAnalysisPermission?.lastSuccessfulRun ||
    sentimentAnalysisPermission?.lastManualJobCompleted

  return (
    <>
      <SentimentAnalysisUserBar
        sentimentAnalysisPermission={sentimentAnalysisPermission}
        userProfile={currentUserData}
        isAnalysing={isAnalysing}
        isUnlinking={isUnlinking}
        noSavedToken={noSavedToken}
        userIsAuthenticated={userIsAuthenticated}
        refreshTokenExpiresOn={refreshTokenExpiresOn}
        generateAzureCode={generateAzureCode}
        analyseLandlordEmails={analyseLandlordEmails}
        azureConnected={azureConnected}
        unlinkEmailAccount={unlinkEmailAccount}
      />
      {noSavedToken && !userHasPreviousData && (
        <div className="p-10 text-center">
          <Button
            loading={isAuthenticating}
            onClick={() => {
              generateAzureCode()
            }}
            fullWidth={false}
            buttonDataTestId="sentiment-analysis-authenticate-button"
          >
            Authenticate my Account
          </Button>

          <HelperArrow className="mb-4 mt-10 w-32" direction="up" />
          <p className="py-2.5 text-base font-medium text-gray-600">
            Authenticate your account to get started.
          </p>
        </div>
      )}

      {(!noSavedToken || userHasPreviousData) && (
        <>
          <div className="flex flex-row flex-wrap justify-end pt-4">
            <SentimentAnalysisFilterBar
              totalLandlordCount={landlordFilterOptions?.length}
              filterByOptions={landlordFilterOptions}
              filterByValues={filterByLandlord}
              setFilterByValues={setFilterByLandlord}
              sortByValues={sortByValues}
              setSortByValues={setSortByValues}
              filterDateRange={filterDateRange}
              setFilterDateRange={setFilterDateRange}
              filterESIRating={filterESIRating}
              setFilterESIRating={setFilterESIRating}
              selectedFlagFilter={selectedFlagFilter}
              setSelectedFlagFilter={setSelectedFlagFilter}
              filterByClientStatus={filterByClientStatus}
              setFilterByClientStatus={setFilterByClientStatus}
              totalFilteredLandlordsCount={totalFilteredLandlordsCount}
            />
            <SentimentAnalysisCell
              currentUserData={currentUserData}
              filterDateRange={filterDateRange}
              filterESIRating={filterESIRating}
              sortByValues={sortByValues}
              filterByLandlord={filterByLandlord}
              filterByFlag={selectedFlagFilter}
              filterByClientStatus={filterByClientStatus}
              setTotalFilteredLandlordsCount={setTotalFilteredLandlordsCount}
            />
          </div>
        </>
      )}
    </>
  )
}

export default SentimentAnalysis
