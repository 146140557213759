import React, { FC } from 'react'

import { Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Link from '@mui/material/Link'
import Popover from '@mui/material/Popover'
import { User } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

interface UserHoverCardProps {
  user: User
  membershipId: number
  enterDelay?: number
  leaveDelay?: number
  showAtSymbol?: boolean
}

export const UserCard = ({ user, elevation = 1, sx = {} }) => {
  return (
    <Card sx={sx} elevation={elevation}>
      <CardHeader
        avatar={<Avatar src={user.avatarUrl} />}
        title={user.name}
        subheader={user.position}
      />
    </Card>
  )
}

const UserHoverCard: FC<UserHoverCardProps> = ({
  user,
  membershipId,
  showAtSymbol = false,
}) => {
  // Handle popover events
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        className={
          'inline-flex text-indigo-600 cursor-pointer items-center mx-0.5'
        }
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={() => {
          navigate(routes.informerDirectory({ membershipId: membershipId }))
        }}
      >
        <Link color="inherit" underline={'hover'} className={'mx-2'}>
          {showAtSymbol && '@'}
          {user.name}
        </Link>
      </Typography>

      <Popover
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <UserCard user={user} />
      </Popover>
    </>
  )
}

export default UserHoverCard
