import { Dispatch, FC, SetStateAction } from 'react'

import { UserCircleIcon } from '@heroicons/react/24/solid'
import { Landlord } from 'types/graphql'

import { UserCard } from 'src/components/Library/UserHoverCard/UserHoverCard'
import { useAuth } from 'src/Providers'

import Empty from '../Empty/Empty'
import Modal from '../Modal/Modal'

import SentimentAnalysisGraph from './SentimentAnalysisGraph/SentimentAnalysisGraph'
import {
  FilterByOption,
  SADateFilterOptionNames,
  SADateFilterOptions,
  SentimentAnalysisMetricName,
} from './SentimentAnalysisHelper'
import SentimentAnalysisCSIScore from './SentimentAnalysisUserBar/SentimentAnalysisCSIScore'

interface SentimentAnalysisOverviewModalProps {
  overviewGraphData: any[]
  overviewModalOpen: boolean
  setOverviewModalOpen: Dispatch<SetStateAction<boolean>>
  filterByLandlord: FilterByOption[]
  filterDateRange: SADateFilterOptions
}

const calculateOverallESI = (landlordsWithEmails: Landlord[]): number => {
  let overallESIScoreSum = 0
  let overallESIScore = 0

  for (const landlord of landlordsWithEmails) {
    const csiRating = landlord.csiRating

    overallESIScoreSum += csiRating
  }

  overallESIScore =
    landlordsWithEmails?.length > 0
      ? Math.round((overallESIScoreSum / landlordsWithEmails.length) * 100) /
        100
      : 0

  return overallESIScore
}

const SentimentAnalysisOverviewModal: FC<
  SentimentAnalysisOverviewModalProps
> = ({
  overviewGraphData,
  overviewModalOpen,
  setOverviewModalOpen,
  filterByLandlord,
  filterDateRange,
}) => {
  const { currentUser } = useAuth()

  const userInfo = {
    name: currentUser.userData.name,
    position: currentUser.userData.position,
    avatarUrl: currentUser.userData.avatarUrl,
  }

  const emails = overviewGraphData.flatMap((landlord) => {
    return landlord?.emails
  })

  const allUnprocessedEmailsCount = overviewGraphData
    .map((landlord) => {
      return landlord?.unprocessedEmailsCount
    })
    .reduce((total, count) => total + count, 0)

  const sortedEmails = [...emails].sort((a, b) => {
    return a.receivedAt < b.receivedAt ? 1 : -1
  })

  const overallESI = calculateOverallESI(overviewGraphData)

  return (
    <Modal
      className="max-w-6xl"
      open={overviewModalOpen}
      onClose={() => {
        setOverviewModalOpen(false)
      }}
    >
      <div className="p-4 pt-0">
        {overviewGraphData?.length > 0 && (
          <div className="flex flex-wrap justify-around">
            <div className="grow px-4">
              <div className="flex items-center justify-between border-b border-gray-200 pb-4">
                <UserCard
                  user={userInfo}
                  elevation={0}
                  sx={{ '& .MuiCardHeader-root': { padding: '0!important' } }}
                />
                <SentimentAnalysisCSIScore
                  overallScore={overallESI}
                  hideCounts
                  tooltip={`Filtered ${SentimentAnalysisMetricName.esi}`}
                />
              </div>
              <div className="mt-4 flex flex-col gap-2">
                <p className="flex items-center justify-between rounded border border-gray-200 p-3">
                  <b>Viewing:</b>
                  <span>{sortedEmails?.length} Emails</span>
                </p>
                <p className="flex items-center justify-between rounded border border-gray-200 p-3">
                  <b>Date Range:</b>
                  <span
                    data-testid={`sa-overview-modal-date-range-${filterDateRange}`}
                  >
                    {SADateFilterOptionNames[filterDateRange]}
                  </span>
                </p>
                <p className="flex flex-wrap items-center justify-between rounded border border-gray-200 p-3">
                  <b>Contacts:</b>
                  {filterByLandlord?.length === 0 && (
                    <span className="">All Contacts</span>
                  )}
                  {filterByLandlord?.length > 0 && (
                    <div className="-mb-2 max-h-[330px] w-full grow overflow-y-scroll pt-2">
                      {filterByLandlord?.map((contact, index) => {
                        return (
                          <p
                            key={index}
                            className="flex w-full items-center gap-2 border-t border-gray-200 py-2"
                          >
                            <UserCircleIcon className="h-5 w-5 text-gray-400" />
                            {contact.name}
                          </p>
                        )
                      })}
                    </div>
                  )}
                </p>
              </div>
            </div>
            <SentimentAnalysisGraph
              emails={sortedEmails}
              filterDateRange={SADateFilterOptions[filterDateRange]}
              unprocessedEmailsCount={allUnprocessedEmailsCount}
            />
          </div>
        )}
        {overviewGraphData?.length === 0 && (
          <div className="py-20">
            <Empty title="There is not enough information to populate the chart." />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default SentimentAnalysisOverviewModal
