import type { FC } from 'react'

import {
  FindSentimentAnalysisQuery,
  FindSentimentAnalysisQueryVariables,
} from 'types/graphql'

import { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { SelectionType } from 'src/components/Library/SelectWithSort/SelectWithSort'
import {
  FilterByOption,
  SADateFilterOptions,
  SentimentAnalysisRatingOptions,
  type SentimentAnalysisFlagOptions,
  type SentimentStatusSelectOptions,
} from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'

import SentimentAnalysisList from '../SentimentAnalysisList/SentimentAnalysisList'

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'no-cache',
  nextFetchPolicy: 'no-cache',
})

export const QUERY = gql`
  query FindSentimentAnalysisQuery($filterDateRange: SADateFilterOptions) {
    landlords: landlordsWithEmailsAndSentimentScores(
      filterDateRange: $filterDateRange
    ) {
      ...LandlordFields
      emails {
        ...LandlordEmailFields
        flaggedEmail {
          ...LandlordFlaggedEmailWithoutResolversFields
        }
      }
    }
    memberships: membershipsByClient(activeOnly: true) {
      id
      role
      memberPositions {
        id
        name
      }
      user {
        id
        name
        email
        avatarUrl
        position
      }
    }
  }
`

type SuccessProps = CellSuccessProps<
  FindSentimentAnalysisQuery,
  FindSentimentAnalysisQueryVariables
> & {
  // Additional props this Cell receives
  sortByValues: SelectionType
  filterByLandlord: FilterByOption[]
  filterDateRange: SADateFilterOptions
  filterESIRating: SentimentAnalysisRatingOptions
  filterByFlag: SentimentAnalysisFlagOptions
  filterByClientStatus: SentimentStatusSelectOptions
  setTotalFilteredLandlordsCount: (count: number) => void
}

export const Loading = () => {
  return (
    <div className="w-full py-10">
      <LoadingSpinner />
    </div>
  )
}

export const Empty: FC<SuccessProps> = (props) => {
  return <SentimentAnalysisList {...props} />
}

export const Failure = ({
  error,
}: CellFailureProps<FindSentimentAnalysisQueryVariables>) => (
  <div className="p-16 text-center text-red-400">Error: {error?.message}</div>
)

export const Success: FC<SuccessProps> = (props) => {
  return <SentimentAnalysisList {...props} />
}
