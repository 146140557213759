import { FLAG_FEATURES, HUBSPOT_FORM_ID } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'

import SentimentAnalysisPermissionCell from '../../components/SentimentAnalysis/SentimentAnalysisPermissionCell'

const SentimentAnalysisPage = () => {
  return (
    <>
      <Metadata title="Sentiment Analysis" description="Sentiment Analysis" />
      <PageHeader
        title="Sentiment Analysis"
        parentDataTestId="sentiment-analysis-page-header"
      />
      <FeatureToggle
        feature={FLAG_FEATURES.SENTIMENT_ANALYSIS}
        InactiveComponent={
          <FeatureDenied hubspotFormId={HUBSPOT_FORM_ID.SENTIMENT_ANALYSIS} />
        }
      >
        <div className="flex min-h-[calc(100vh-80px)] w-full flex-col bg-white">
          <div className="mx-auto flex h-full w-full flex-1 grow flex-col p-6">
            <SentimentAnalysisPermissionCell />
          </div>
        </div>
      </FeatureToggle>
    </>
  )
}

export default SentimentAnalysisPage
